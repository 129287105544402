import { reactLocalStorage } from "reactjs-localstorage";
var ApiHelper = {};
ApiHelper.baseURL = "https://samphion.net:4005/adminpanelapi/v1/"
// ApiHelper.baseURL =
//   "ec2-15-207-17-31.ap-south-1.compute.amazonaws.com:8082/adminpanelapi/v1/";
// ApiHelper.baseURL = "http://localhost:8082/adminpanelapi/v1/";
// ApiHelper.baseURL = "http://15.207.17.31:8082/adminpanelapi/v1/";
// ApiHelper.baseURL = "http://65.1.251.71:8080/adminpanelapi/v1/";
ApiHelper.appApiBaseURL = "https://samphion.net:8083/api/";

ApiHelper.authentication = {};
ApiHelper.authentication.login = ApiHelper.baseURL + "authentication/login";
ApiHelper.authentication.sendOTP = ApiHelper.baseURL + "authentication/sendotp";
ApiHelper.authentication.verifyOTP =
  ApiHelper.baseURL + "authentication/verifyotp";
ApiHelper.authentication.updatePasswordWithOTPAndEmail =
  ApiHelper.baseURL + "authentication/updatepasswordwithotpandemail";

ApiHelper.adminCredentials = {};
ApiHelper.adminCredentials.getAdminCredentialsByOffset =
  ApiHelper.baseURL + "admincredentials/getadmincredentialsbyoffset";
ApiHelper.adminCredentials.getAuthorityLevels =
  ApiHelper.baseURL + "admincredentials/getauthoritylevels";
ApiHelper.adminCredentials.addAdminCredential =
  ApiHelper.baseURL + "admincredentials/addadmincredential";
ApiHelper.adminCredentials.deleteAdminWithSerialID =
  ApiHelper.baseURL + "admincredentials/deleteadminwithserialid";
ApiHelper.adminCredentials.updatePasswordWithSerialID =
  ApiHelper.baseURL + "admincredentials/updatepasswordwithserialid";
ApiHelper.adminCredentials.getMyAdminCredentialWithSerialID =
  ApiHelper.baseURL + "admincredentials/getmyadmincredentialwithserialid";

ApiHelper.categoryScreen = {};
ApiHelper.categoryScreen.getLifeStyleCategoriesByOffset =
  ApiHelper.baseURL + "categoryscreen/getlifestylecategoriesbyoffset";
ApiHelper.categoryScreen.removeLifeStyleCategory =
  ApiHelper.baseURL + "categoryscreen/removelifestylecategory";
ApiHelper.categoryScreen.addLifeStyleCategory =
  ApiHelper.baseURL + "categoryscreen/addlifestylecategory";
ApiHelper.categoryScreen.updateLifeStyleCategory =
  ApiHelper.baseURL + "categoryscreen/updatelifestylecategory";

ApiHelper.primaryInterest = {};
ApiHelper.primaryInterest.getInterestsByOffset =
  ApiHelper.baseURL + "primaryinterest/getinterestsbyoffset";
ApiHelper.primaryInterest.getInterests =
  ApiHelper.baseURL + "primaryinterest/getInterests";
ApiHelper.primaryInterest.removeInterest =
  ApiHelper.baseURL + "primaryinterest/removeinterest";
ApiHelper.primaryInterest.addInterest =
  ApiHelper.baseURL + "primaryinterest/addinterest";
ApiHelper.primaryInterest.updateInterest =
  ApiHelper.baseURL + "primaryinterest/updateinterest";

ApiHelper.userStatistics = {};
ApiHelper.userStatistics.getDailyActiveUsers =
  ApiHelper.baseURL + "userstatistics/getdailyactiveusers";
ApiHelper.userStatistics.getDailyMessageExchange =
  ApiHelper.baseURL + "userstatistics/getdailymessageexchange";
ApiHelper.userStatistics.getTotalMessagesCount =
  ApiHelper.baseURL + "userstatistics/gettotalmessagescount";
ApiHelper.userStatistics.getTotalSamphiesSent =
  ApiHelper.baseURL + "userstatistics/gettotalsamphiessent";
ApiHelper.userStatistics.getTotalLikesSent =
  ApiHelper.baseURL + "userstatistics/gettotallikessent";
ApiHelper.userStatistics.getTotalDislikesSent =
  ApiHelper.baseURL + "userstatistics/gettotaldislikessent";
ApiHelper.userStatistics.getPercentOfVerifiedMaleUsers =
  ApiHelper.baseURL + "userstatistics/getpercentageofmaleverifiedusers";
ApiHelper.userStatistics.getPercentOfVerifiedFemaleUsers =
  ApiHelper.baseURL + "userstatistics/getpercentageoffemaleverifiedusers";
ApiHelper.userStatistics.getCountOfVerifiedMaleUsers =
  ApiHelper.baseURL + "userstatistics/getcountofmaleverifiedusers";
ApiHelper.userStatistics.getCountOfVerifiedFemaleUsers =
  ApiHelper.baseURL + "userstatistics/getcountoffemaleverifiedusers";
ApiHelper.userStatistics.getPercentOfMaleSamphionisters =
  ApiHelper.baseURL + "userstatistics/getpercentageofmalesamphionisterprofiles";
ApiHelper.userStatistics.getPercentOfFemaleSamphionisters =
  ApiHelper.baseURL +
  "userstatistics/getpercentageoffemalesamphionisterprofiles";
ApiHelper.userStatistics.getTotalFemaleUsers =
  ApiHelper.baseURL + "userstatistics/gettotalfemaleusers";
ApiHelper.userStatistics.getTotalMaleUsers =
  ApiHelper.baseURL + "userstatistics/gettotalmaleusers";
ApiHelper.userStatistics.getTotalPaidUsers =
  ApiHelper.baseURL + "userstatistics/gettotalpaidusers";
ApiHelper.userStatistics.getTotalSamphionisters =
  ApiHelper.baseURL + "userstatistics/gettotalsamphionisters";
ApiHelper.userStatistics.getTotalMaleSamphionisters =
  ApiHelper.baseURL + "userstatistics/getnoofmalesamphionisters";
ApiHelper.userStatistics.getTotalFemaleSamphionisters =
  ApiHelper.baseURL + "userstatistics/getnooffemalesamphionisters";
ApiHelper.userStatistics.getTopUsers =
  ApiHelper.baseURL + "userstatistics/gettopusers";
ApiHelper.userStatistics.getDownloadsGrowth =
  ApiHelper.baseURL + "userstatistics/getdownloadsgrowth";
ApiHelper.userStatistics.getUsersByPeriod =
  ApiHelper.baseURL + "userstatistics/getusersbyperiod";
ApiHelper.userStatistics.getMembershipGrowth =
  ApiHelper.baseURL + "userstatistics/getmembershipgrowth";
ApiHelper.userStatistics.getUpliftGrowth =
  ApiHelper.baseURL + "userstatistics/getupliftgrowth";
ApiHelper.userStatistics.getSamphiePackGrowth =
  ApiHelper.baseURL + "userstatistics/getsamphiepackgrowth";
ApiHelper.userStatistics.getPercentageOfVerifiedProfilesByPeriod =
  ApiHelper.baseURL + "userstatistics/getpercentageofverifiedprofilesbyperiod";
ApiHelper.userStatistics.getCountOfVerifiedProfilesByPeriod =
  ApiHelper.baseURL + "userstatistics/getcountofverifiedprofilesbyperiod";
ApiHelper.userStatistics.getPercentageOfSamphionistersByPeriod =
  ApiHelper.baseURL + "userstatistics/getpercentageofsamphionistersbyperiod";
ApiHelper.userStatistics.getVerifiedUsers =
  ApiHelper.baseURL + "userstatistics/getverifiedusers";
ApiHelper.userStatistics.getSuspendedUsers =
  ApiHelper.baseURL + "userstatistics/getsuspendedusers";
ApiHelper.userStatistics.getTotalMatches =
  ApiHelper.baseURL + "userstatistics/gettotalmatches";
ApiHelper.userStatistics.getUsersByOffset =
  ApiHelper.baseURL + "userstatistics/getUsersByOffset";
ApiHelper.userStatistics.getUsersByCategory =
  ApiHelper.baseURL + "userstatistics/getUsersByCategory";

ApiHelper.userDetails = {};
ApiHelper.userDetails.excludeFromTopUsers =
  ApiHelper.baseURL + "userdetails/excludefromtopusers";
ApiHelper.userDetails.excludeFromExclusiveUsers =
  ApiHelper.baseURL + "userdetails/excludeFromExclusiveUsers";
ApiHelper.userDetails.getUserDetails =
  ApiHelper.baseURL + "userdetails/getuserdetails";
ApiHelper.userDetails.getSuspendedUserDetails =
  ApiHelper.baseURL + "userdetails/getsuspendeduserdetails";
ApiHelper.userDetails.getUserName =
  ApiHelper.baseURL + "userdetails/getusername";
ApiHelper.userDetails.getUserMatches =
  ApiHelper.baseURL + "userdetails/getusermatches";
ApiHelper.userDetails.editVerified =
  ApiHelper.baseURL + "userdetails/editVerified";
ApiHelper.userDetails.editSamphionister =
  ApiHelper.baseURL + "userdetails/editSamphionister";

ApiHelper.userReports = {};
ApiHelper.userReports.getUserReports =
  ApiHelper.baseURL + "userreports/getuserreports";
ApiHelper.userReports.getReportDetails =
  ApiHelper.baseURL + "userreports/getreportdetails";
ApiHelper.userReports.suspendReportedUser =
  ApiHelper.baseURL + "userreports/suspendreporteduser";
ApiHelper.userReports.dismissUserReport =
  ApiHelper.baseURL + "userreports/dismissuserreport";

ApiHelper.verificationRequests = {};
ApiHelper.verificationRequests.getVerificationRequests =
  ApiHelper.baseURL + "verificationrequests/getverificationrequests";
ApiHelper.verificationRequests.getVerificationRequest =
  ApiHelper.baseURL + "verificationrequests/getverificationrequest";
ApiHelper.verificationRequests.acceptVerificationRequest =
  ApiHelper.baseURL + "verificationrequests/acceptverificationrequest";
ApiHelper.verificationRequests.rejectVerificationRequest =
  ApiHelper.baseURL + "verificationrequests/rejectverificationrequest";

ApiHelper.swipes = {};
ApiHelper.swipes.getDailyGrowth = ApiHelper.baseURL + "swipes/getdailygrowth";
ApiHelper.swipes.getWeeklyGrowth = ApiHelper.baseURL + "swipes/getweeklygrowth";
ApiHelper.swipes.getMonthlyGrowth =
  ApiHelper.baseURL + "swipes/getmonthlygrowth";
ApiHelper.swipes.get3MonthsGrowth =
  ApiHelper.baseURL + "swipes/get3monthsgrowth";

ApiHelper.readablecontent = {};
ApiHelper.readablecontent.createCategory =
  ApiHelper.baseURL + "readablecontent/createCategory";
ApiHelper.readablecontent.getAllCategories =
  ApiHelper.baseURL + "readablecontent/getAllCategories";
ApiHelper.readablecontent.deleteCategory =
  ApiHelper.baseURL + "readablecontent/deleteCategory";
ApiHelper.readablecontent.createQuotes =
  ApiHelper.baseURL + "readablecontent/createQuotes";
ApiHelper.readablecontent.getAllQuotes =
  ApiHelper.baseURL + "readablecontent/getAllQuotes";
ApiHelper.readablecontent.deleteQuotes =
  ApiHelper.baseURL + "readablecontent/deleteQuotes";
ApiHelper.readablecontent.createFacts =
  ApiHelper.baseURL + "readablecontent/createFacts";
ApiHelper.readablecontent.getAllFacts =
  ApiHelper.baseURL + "readablecontent/getAllFacts";
ApiHelper.readablecontent.deleteFact =
  ApiHelper.baseURL + "readablecontent/deleteFact";
ApiHelper.readablecontent.createBlogs =
  ApiHelper.baseURL + "readablecontent/createBlogs";
ApiHelper.readablecontent.getAllBlogs =
  ApiHelper.baseURL + "readablecontent/getAllBlogs";
ApiHelper.readablecontent.deleteBlog =
  ApiHelper.baseURL + "readablecontent/deleteBlog";
ApiHelper.readablecontent.getAllJokes =
  ApiHelper.baseURL + "readablecontent/getAllJokes";
ApiHelper.readablecontent.createJokes =
  ApiHelper.baseURL + "readablecontent/createJokes";
ApiHelper.readablecontent.deleteJokes =
  ApiHelper.baseURL + "readablecontent/deleteJokes";

ApiHelper.appApi = {};
ApiHelper.appApi.uploadImage = ApiHelper.appApiBaseURL + "upload";

ApiHelper.ads = {};
ApiHelper.ads.getAdOwners = ApiHelper.baseURL + "ad/get-ad-owners";
ApiHelper.ads.postNewAd = ApiHelper.baseURL + "ad/";
ApiHelper.ads.getAdByOwnerId = ApiHelper.baseURL + "ad/ad-owner";
ApiHelper.ads.getAllAds = ApiHelper.baseURL + "ad/";
ApiHelper.ads.getAllAdsAdmin = ApiHelper.baseURL + "ad/admin";
ApiHelper.ads.getAdById = ApiHelper.baseURL + "ad/getad";
ApiHelper.ads.deleteAd = ApiHelper.baseURL + "ad/delete";
ApiHelper.ads.resumeAd = ApiHelper.baseURL + "ad/resume";
ApiHelper.ads.makeAdsVisible = ApiHelper.baseURL + "ad/visible";
ApiHelper.ads.makeAdsInVisible = ApiHelper.baseURL + "ad/invisible";
ApiHelper.ads.checkAdsVisibility = ApiHelper.baseURL + "ad/check-visibility";

ApiHelper.localStorage = {};

ApiHelper.getAccessTokenHeaderConfig = () => {
  var token = reactLocalStorage.getObject(
    ApiHelper.localStorage.keys.logintokens
  );
  if (token === undefined) {
    reactLocalStorage.clear();
    return {};
  }
  return {
    headers: {
      Authorization: "Bearer " + token.tokens.accesstoken,
    },
  };
};
ApiHelper.localStorage.keys = {};
ApiHelper.localStorage.keys.logintokens = "logintokens";
ApiHelper.localStorage.keys.adminData = "adminData";
ApiHelper.localStorage.setTokens = (tokens) => {
  reactLocalStorage.setObject(ApiHelper.localStorage.keys.logintokens, {
    tokens,
    logintime: parseInt(new Date().getTime() / 1000),
  });
};
ApiHelper.localStorage.setAdminData = (adminData) => {
  reactLocalStorage.setObject(ApiHelper.localStorage.keys.adminData, adminData);
};
ApiHelper.localStorage.getAdminData = () => {
  return reactLocalStorage.getObject(ApiHelper.localStorage.keys.adminData);
};
ApiHelper.localStorage.getLoginData = () => {
  return reactLocalStorage.getObject(ApiHelper.localStorage.keys.logintokens);
};
ApiHelper.localStorage.isUserLoggedIn = () => {
  var token = reactLocalStorage.getObject(
    ApiHelper.localStorage.keys.logintokens
  );
  if (Object.keys(token).length === 0) {
    reactLocalStorage.clear();
    return false;
  }
  return true;
};
ApiHelper.localStorage.logout = () => {
  reactLocalStorage.clear();
};
export default ApiHelper;
